<template>
  <el-table
    ref="tableControl"
    :key="updateTable"
    v-loading="isLoading"
    class="table-control"
    :data="tableData"
    :row-class-name="tableRowClassName"
    style="width: 100%"
    stripe
    @selection-change="handleSelectionChange"
    @cell-mouse-enter="handleCellMouseEnter"
    @cell-mouse-leave="handleCellMouseLeave"
  >
    <el-table-column type="selection" :width="isTablet ? '40' : '80'" />

    <template v-for="item in controlTableFields">
      <el-table-column
        :key="item.id"
        :width="item.width ? item.width : ''"
        :class-name="`table-control__${item.prop}`"
        :sortable="item.sortKey"
        :align="item.align"
      >
        <template slot="header">
          <TableLabel
            :data="item"
            :sortable="item.sortKey"
            @sortBy="handleSort"
          />
        </template>

        <template slot-scope="scope">
          <slot :data="{ row: scope.row, prop: item.prop }" />
        </template>
      </el-table-column>
    </template>

    <el-table-column
      align="right"
      :width="getHeightIconColumn"
      class-name="table-control__icon"
    >
      <template slot="header">
        <el-dropdown
          v-if="hasIcons"
          trigger="click"
          class="table-control__header-dropdown"
          @command="handleTableMenu"
        >
          <span class="el-dropdown-link">
            <Icon
              name="IconTableMenu"
              width="20px"
              height="20px"
              class="table-control__menu"
            />
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="item in tableDropdownMenuList"
              :key="item.id"
              :class="item.class"
              :divided="item.isDivided"
              :disabled="isDropdownItemDisabled(item)"
              :command="item.value"
            >
              {{ item.label }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </template>

      <template slot-scope="scope">
        <Icon
          v-if="hasIcons"
          name="IconTableEdit"
          width="20px"
          height="20px"
          class="table-control__menu"
          @click.native="handleEdit(scope.row)"
        />

        <Icon
          v-if="hasIcons"
          name="IconTableDelete"
          width="20px"
          height="20px"
          class="table-control__menu"
          @click.native="handleDelete(scope.row)"
        />
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { controlTableRemoveSelectedItems } from '@/views/control/data/control'
import Icon from '@/UI/icon/Icon'
import TableLabel from '@/UI/table/table-body/components/TableLabel'
export default {
  name: 'TableBody',
  components: { TableLabel, Icon },
  props: {
    tableData: { type: Array, required: true },
    tableFieldsDesktop: { type: Array, required: true },
    tableFieldsTablet: { type: Array, default: () => [] },
    tableFieldsMobile: { type: Array, default: () => [] },
    tableFieldsSmallMobile: { type: Array, default: () => [] },
    tableDropdownMenuList: { type: Array, required: true },
    hasIcons: { type: Boolean, default: true },
    isLoading: { type: Boolean, default: false },
  },
  data() {
    return {
      multipleSelectionList: [],
      multipleSelectionIds: [],
      multipleSelectionNames: [],
      multipleSelectionLocalNames: [],
      hoverRowIds: [],
    }
  },
  computed: {
    controlTableFields() {
      if (this.isSmallMobile) {
        return this.tableFieldsSmallMobile
      }
      if (this.isMobile) {
        return this.tableFieldsMobile
      }
      if (this.isTablet) {
        return this.tableFieldsTablet
      }

      return this.tableFieldsDesktop
    },
    updateTable() {
      if (this.isSmallMobile) {
        return 'smallMobile'
      }
      if (this.isMobile) {
        return 'mobile'
      }
      if (this.isTablet) {
        return 'tablet'
      }

      return 'desktop'
    },
    getHeightIconColumn() {
      return this.isSmallMobile ? '62' : '100'
    },
  },
  methods: {
    isDropdownItemDisabled({ value }) {
      return (
        value === controlTableRemoveSelectedItems &&
        this.multipleSelectionIds.length === 0
      )
    },
    tableRowClassName({ row }) {
      if (this.multipleSelectionIds.includes(row.id)) {
        return 'active-row'
      }
      if (this.hoverRowIds.includes(row.id)) {
        return 'hover-row'
      }

      return ''
    },
    handleSelectionChange(val) {
      this.multipleSelectionList = val
      this.multipleSelectionIds = val.map(item => item.id)
      this.multipleSelectionNames = val.map(item => item.name)
      this.multipleSelectionLocalNames =
        val.map(item => (item.local_name ? item.local_name : item.name)) || []
      this.$emit('selectionChange', {
        ids: this.multipleSelectionIds,
        names: this.multipleSelectionNames,
        local_names: this.multipleSelectionLocalNames,
        list: this.multipleSelectionList,
      })
    },
    handleTableMenu(command) {
      this.$emit('selectDropdownMenu', command)
    },
    handleEdit(row) {
      this.$emit('editRow', row)
    },
    handleDelete(row) {
      this.$emit('deleteRow', row)
    },
    handleSort(sortKey) {
      this.$emit('tableSortByKey', sortKey)
    },
    handleCellMouseEnter(row) {
      this.hoverRowIds = []
      this.hoverRowIds.push(row.id)
    },
    handleCellMouseLeave() {
      this.hoverRowIds = []
    },
  },
}
</script>
