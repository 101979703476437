<template>
  <div :class="['table-control-label', data.prop]">
    <div v-if="isLabelArray">
      <div v-for="(item, index) in data.label" :key="index">
        {{ item }}
      </div>
    </div>

    <div
      v-else
      class="table-control-label__label"
      @click="handleSort(data.sortKey)"
    >
      <span>{{ data.label }}</span>
      <span v-if="data.headerIcon" class="my-caret-wrapper">
        <i class="sort-caret ascending el-caret" />
        <i class="sort-caret descending el-caret" />
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TableLabel',
  props: { data: { type: Object, default: () => ({}) } },
  computed: {
    isLabelArray() {
      return Array.isArray(this.data.label)
    },
  },
  methods: {
    handleSort(event) {
      this.$emit('sortBy', event)
    },
  },
}
</script>

<style lang="sass">

.table-control-label
  width: 100%
  &__label
    flex: 1 0 auto
    .my-caret-wrapper
      display: inline-flex
      flex-direction: column
      align-items: center
      height: 34px
      width: 24px
      vertical-align: middle
      cursor: pointer
      overflow: initial
      position: relative
    .el-caret
      display: block
      left: 20px
      z-index: 1 !important

  &__icon
    cursor: pointer
</style>
