var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: ["table-control-label", _vm.data.prop] }, [
    _vm.isLabelArray
      ? _c(
          "div",
          _vm._l(_vm.data.label, function (item, index) {
            return _c("div", { key: index }, [_vm._v(" " + _vm._s(item) + " ")])
          }),
          0
        )
      : _c(
          "div",
          {
            staticClass: "table-control-label__label",
            on: {
              click: function ($event) {
                return _vm.handleSort(_vm.data.sortKey)
              },
            },
          },
          [
            _c("span", [_vm._v(_vm._s(_vm.data.label))]),
            _vm.data.headerIcon
              ? _c("span", { staticClass: "my-caret-wrapper" }, [
                  _c("i", { staticClass: "sort-caret ascending el-caret" }),
                  _c("i", { staticClass: "sort-caret descending el-caret" }),
                ])
              : _vm._e(),
          ]
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }